import EventBus from '@/util/EventBus'
import UserService from '@/services/user.service'
import HoneyPotService from '@/services/honey-pot.service'

export default {
  name: 'LoginTwoFactor',
  props: {
    auth: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      manyAttempts: false,
      twoWayCodeHelper: Array(6).fill(''),
      honeypotValue: '',
      honeypotFieldName: '',
      renderHoneypot: false,
      honeyPotInputFieldName: '',
      validFrom: '',
    }
  },
  watch: {
    twoWayCodeHelper: {
      deep: true,
      handler (newVal) {
        if (!this.hasEmptyFields) {
          setTimeout(() => {
            this.auth.twoWayCode = this.twoWayCodeHelper.join('')
            this.handletwoWayCheck()
          }, 50)
        }
      },
    },
  },
  computed: {
    hasEmptyFields () {
      return !this.twoWayCodeHelper.every(val => val.trim().length === 1);
    },
  },
  methods: {
    setLastCharWithTimeout(index, value) {
      return new Promise((resolve) => {
        const lastChar = value.charAt(value.length - 1);
        setTimeout(() => {
          this.$set(this.twoWayCodeHelper, index, lastChar);
          resolve(lastChar);
        });
      });
    },
    async focusNext (index, event) {
      const value = this.$refs.codeInputs[index].$el.querySelector('input').value

      if (value && value.length > 1) {
        await this.setLastCharWithTimeout(index, value)
      }

      if (!/^\d*$/.test(value)) {
        this.$set(this.twoWayCodeHelper, index, '');
        return
      }

      if (value.length === 1 && index < 5) {
        this.$nextTick(() => {
          const nextInput = this.$refs.codeInputs[index + 1]

          if (nextInput) {
            nextInput.$el.querySelector('input').focus()
          }
        })
      }
    },
    handleBackspace (index, event) {
      if (event.key === 'Backspace' && !this.twoWayCodeHelper[index] && index > 0) {
        this.$nextTick(() => {
          const prevInput = this.$refs.codeInputs[index - 1]
          if (prevInput) {
            prevInput.$el.querySelector('input').focus()
          }
        })
      }
    },
    handlePaste (index, event) {
      const text = event.clipboardData.getData('Text')
      event.preventDefault()
      setTimeout(() => {
        const newCodeHelper = [...this.twoWayCodeHelper]
        for (let i = 0; i < text.length; i++) {
          if (index + i < 6) {
            newCodeHelper[index + i] = text[i]
          }
        }
        this.twoWayCodeHelper = newCodeHelper
      })
    },
    async handletwoWayCheck () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true

      const dataToSend = { ...this.auth }
      dataToSend[this.honeyPotInputFieldName] = this.honeypotValue
      dataToSend.honeyPotInputFieldName = this.honeyPotInputFieldName
      dataToSend.validFrom = this.validFrom

      this.$store.dispatch('auth/twoWayCheck', dataToSend).then(
        () => {
          this.getSectionsBlocked()
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar de iniciar sesión'))
          }
          if (error.response.data.code === 'login.manyAttempts') {
            this.manyAttempts = true
            return false
          }

          if (error.response.status === 400) {
            EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
          }
        },
      )
      .finally(() => {
        this.loading = false
      })
    },

    getSectionsBlocked () {
      UserService.getSectionsBlocked().then(
        (response) => {
          this.sectionsBlocked(response)
          this.$router.push('/home')
        },
        (error) => {
          console.log(error)
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar de iniciar sesión'))
          }
          EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.error))
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    sectionsBlocked (sectionsBlockeds) {
      const data = []
      for (const sectionBlock of sectionsBlockeds) {
        data.push(sectionBlock.toLowerCase())
      }
      localStorage.setItem('sectionsBlockeds', JSON.stringify(data))
    },
  },
  async created () {
    try {
      const data = await HoneyPotService.getHoneyPotFieldName()
      this.honeyPotFieldName = data.name
      this.honeyPotInputFieldName = data.name
      this.validFrom = data.valid_from
      this.renderHoneypot = true
    } catch (error) {
      EventBus.$emit('showAlert', 'danger', this.$t('Ocurrio un error al intentar inicializar el formulario'))
    }
  },
}
