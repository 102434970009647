import { Service } from './service'

const PATH = 'honeypot'

class HoneyPotService extends Service {
  getHoneyPotFieldName (data = {}) {
    return this.get(PATH + '/honeypot-field', data)
  }
}

export default new HoneyPotService()
